import reddit from 'images/icon-reddit.svg'
import trustpilot from 'images/logo-trustpilot.svg'
import twitter from 'images/icon-twitter.svg'

import redditVip from './images/reddit-vip.svg'
import trustpilotVip from './images/trustpilot-vip.svg'
import twitterVip from './images/twitter-vip.svg'

import gokuafrica from './avatars/gokuafrica.png'
import titan from './avatars/titan.png'
import valerios from './avatars/valerios-baglaridis.png'
import reuben from './avatars/reuben-firo.png'
import vik from './avatars/vik-cake.png'
import patricia from './avatars/patricia.png'

const imageSizes = {
  twitter: 69,
  trustpilot: 120,
  reddit: 147,
}

export const slides = [
  {
    id: 1,
    link:
      'https://www.reddit.com/r/Monero/comments/ooqc4p/borrowing_xmr_at_coinrabbit/h6z923w/?utm_source=reddit&utm_medium=web2x&context=3',
    author: {
      name: 'Vik Sharma',
      position: 'Cake Wallet CEO',
      avatar: vik,
      avatarAlt: 'Crypto loans review 1',
    },
    image: reddit,
    imageWidth: imageSizes.reddit,
    title: <>Reliable, safe and fast Crypto loans</>,
    text: (
      <>
        I&apos;ve used CoinRabbit multiple times and found their service to be reliable, safe, and fast. The customer
        service has been spectacular as well. I would recommend this to anyone who wants a loan against their crypto.
      </>
    ),
  },
  {
    id: 2,
    link: 'https://www.trustpilot.com/reviews/60ac6e23f9f4870a94ac0fca',
    author: {
      name: 'Titan',
      avatar: titan,
      avatarAlt: 'Crypto loans review 2',
    },
    image: trustpilot,
    imageWidth: imageSizes.trustpilot,
    imageAlt: 'Crypto loans review 7',
    title: <>CoinRabbit has fully earned my trust</>,
    text: (
      <>
        Coinrabbit has fully earned my trust and I will use them for life! Quick response with customer service and good
        people. Love Coinrabbit!!
      </>
    ),
  },
  {
    id: 3,
    link: 'https://twitter.com/walerikus/status/1420427814000644099?s=21',
    author: {
      name: 'Valerios Baglaridis',
      position: 'DGB Influencer',
      avatar: valerios,
      avatarAlt: 'Crypto loans review 3',
    },
    image: twitter,
    imageWidth: imageSizes.twitter,
    title: <>By far the best user experience provided by CoinRabbit</>,
    text: (
      <>
        CoinRabbit provides by far the best user experience, and I have tried nexo finances, celsius and various
        ethereum based defi protocols like MakerDAO and compound finance.
      </>
    ),
  },
  {
    id: 4,
    link: 'https://www.trustpilot.com/reviews/609f0c84f9f4870b7003f736',
    author: {
      name: 'Patricia Comencini',
      avatar: patricia,
      avatarAlt: 'Crypto loans review 4',
    },
    image: trustpilot,
    imageWidth: imageSizes.trustpilot,
    imageAlt: 'Crypto loans review 8',
    title: <>All great!</>,
    text: (
      <>
        All great! I tried first time and was everything smooth and i have received the loan! with dogecoin. easy
        process
      </>
    ),
  },
  {
    id: 5,
    link: 'https://twitter.com/firoorg/status/1403350419279998981?s=21',
    author: {
      name: 'Reuben',
      position: 'FIRO cofounder',
      avatar: reuben,
      avatarAlt: 'Crypto loans review 5',
    },
    image: twitter,
    imageWidth: imageSizes.twitter,
    title: <>Seamless and quick FIRO loans without registration</>,
    text: (
      <>
        Getting a stablecoin loan on CoinRabbit using FIRO is seamless and quick without requiring user registration.
        Its flexibility in having loans with no fixed duration are also very attractive allowing you to hodl your crypto
        while having USD to spend.
      </>
    ),
  },
  {
    id: 6,
    link: 'https://twitter.com/gokuafrica/status/1421938014897410048?s=21',
    author: {
      name: '@Gokuafrika',
      avatar: gokuafrica,
      avatarAlt: 'Crypto loans review 6',
    },
    image: twitter,
    imageWidth: imageSizes.twitter,
    title: <>Friendly loan terms: choose when to make payments yourself</>,
    text: (
      <>
        I am still riding the wave. Too early to make any profits off my loans. The cool thing is that by putting crypto
        as collateral and using your friendly loan terms, I can choose when to make payments to close/partially close
        loans. This is awesome as this frees me a lot!
      </>
    ),
  },
]

export const slidesVip = [
  {
    id: 1,
    link:
      'https://www.reddit.com/r/Monero/comments/ooqc4p/borrowing_xmr_at_coinrabbit/h6z923w/?utm_source=reddit&utm_medium=web2x&context=3',
    author: {
      name: 'Vik Sharma',
      position: 'Cake Wallet CEO',
      avatar: vik,
      avatarAlt: 'Crypto loans review 1',
    },
    image: redditVip,
    imageWidth: imageSizes.reddit,
    title: <>Reliable, safe and fast Crypto loans</>,
    text: (
      <>
        I&apos;ve used CoinRabbit multiple times and found their service to be reliable, safe, and fast. The customer
        service has been spectacular as well. I would recommend this to anyone who wants a loan against their crypto.
      </>
    ),
  },
  {
    id: 2,
    link: 'https://www.trustpilot.com/reviews/60ac6e23f9f4870a94ac0fca',
    author: {
      name: 'Titan',
      avatar: titan,
      avatarAlt: 'Crypto loans review 2',
    },
    image: trustpilotVip,
    imageWidth: imageSizes.trustpilot,
    imageAlt: 'Crypto loans review 7',
    title: <>CoinRabbit has fully earned my trust</>,
    text: (
      <>
        Coinrabbit has fully earned my trust and I will use them for life! Quick response with customer service and good
        people. Love Coinrabbit!!
      </>
    ),
  },
  {
    id: 3,
    link: 'https://twitter.com/walerikus/status/1420427814000644099?s=21',
    author: {
      name: 'Valerios Baglaridis',
      position: 'DGB Influencer',
      avatar: valerios,
      avatarAlt: 'Crypto loans review 3',
    },
    image: twitterVip,
    imageWidth: imageSizes.twitter,
    title: <>By far the best user experience provided by CoinRabbit</>,
    text: (
      <>
        CoinRabbit provides by far the best user experience, and I have tried nexo finances, celsius and various
        ethereum based defi protocols like MakerDAO and compound finance.
      </>
    ),
  },
  {
    id: 4,
    link: 'https://www.trustpilot.com/reviews/609f0c84f9f4870b7003f736',
    author: {
      name: 'Patricia Comencini',
      avatar: patricia,
      avatarAlt: 'Crypto loans review 4',
    },
    image: trustpilotVip,
    imageWidth: imageSizes.trustpilot,
    imageAlt: 'Crypto loans review 8',
    title: <>All great!</>,
    text: (
      <>
        All great! I tried first time and was everything smooth and i have received the loan! with dogecoin. easy
        process
      </>
    ),
  },
  {
    id: 5,
    link: 'https://twitter.com/firoorg/status/1403350419279998981?s=21',
    author: {
      name: 'Reuben',
      position: 'FIRO cofounder',
      avatar: reuben,
      avatarAlt: 'Crypto loans review 5',
    },
    image: twitterVip,
    imageWidth: imageSizes.twitter,
    title: <>Seamless and quick FIRO loans without registration</>,
    text: (
      <>
        Getting a stablecoin loan on CoinRabbit using FIRO is seamless and quick without requiring user registration.
        Its flexibility in having loans with no fixed duration are also very attractive allowing you to hodl your crypto
        while having USD to spend.
      </>
    ),
  },
  {
    id: 6,
    link: 'https://twitter.com/gokuafrica/status/1421938014897410048?s=21',
    author: {
      name: '@Gokuafrika',
      avatar: gokuafrica,
      avatarAlt: 'Crypto loans review 6',
    },
    image: twitterVip,
    imageWidth: imageSizes.twitter,
    title: <>Friendly loan terms: choose when to make payments yourself</>,
    text: (
      <>
        I am still riding the wave. Too early to make any profits off my loans. The cool thing is that by putting crypto
        as collateral and using your friendly loan terms, I can choose when to make payments to close/partially close
        loans. This is awesome as this frees me a lot!
      </>
    ),
  },
]
